@use 'theme';
@use 'fonts';
@use 'borders';
@use 'breakpoints';
@use 'transitions';

@keyframes grow {
  from {
    transform: translate3d(0, 5%, 0) rotate3d(1, 0, 0, 25deg);
  }
  to {
    transform: scale(1);
  }
}

@keyframes growWithScale {
  from {
    transform: translate3d(0, 5%, 0) rotate3d(1, 0, 0, 25deg) scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

@keyframes growMobile {
  from {
    transform: translate3d(0, 10%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from { opacity: 0 }
  to { opacity: 1 }
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  animation: fadeIn 300ms ease;
  background-color: theme.get('overlayBackground');
  overflow: auto;
  transition: transitions.get('opacity', 300ms);
  z-index: 105;

  &.fadingOut {
    opacity: 0;
  }
}

.box {
  position: relative;
  background-color: theme.palette('white');
  margin: theme.spacing('auto', 0, 0, 0);
  border-radius: borders.radius('md');
  animation: growMobile 300ms ease;
  transition: transitions.get('transform');

  &.transparent {
    background-color: transparent;
  }

  .fadingOut & {
    transform: translate3d(0, 10%, 0);
  }

  @include breakpoints.up('small') {
    margin: theme.spacing('auto');
    border-radius: borders.radius('sm');
    animation: grow 300ms ease;

    &.withScale {
      animation: growWithScale 300ms ease;

      .fadingOut & {
        transform: translate3d(0, 5%, 0) rotate3d(1, 0, 0, 25deg) scale(0.9);
      }
    }

    .fadingOut & {
      transform: translate3d(0, 5%, 0) rotate3d(1, 0, 0, 25deg);
    }
  }
}

.boxSmall {
  width: 100%;

  @include breakpoints.up('small') {
    width: auto;
    max-width: 720px;
  }
}

.boxBig {
  width: 100%;

  @include breakpoints.up('small') {
    width: auto;
    max-width: 100%;
  }
}

.boxFullscreen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  @include breakpoints.up('small') {
    width: 90%;
  }
}

.header {
  display: flex;
}

.title {
  display: flex;
  flex: 1;
  margin-top: theme.spacing(3);
  padding-left: theme.spacing(3);
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: fonts.size('large');

  @include breakpoints.up('small') {
    font-size: fonts.size('xLarge');
    min-height: 0;
    justify-content: flex-start;
    margin-top: theme.spacing(0);
    padding-left: theme.spacing(2.5);

    &.hasTitle {
      min-height: 96px;
    }

    &.isSmallContent {
      padding-left: theme.spacing(3);
    }
  }
}

.close {
  display: none;
  align-items: center;
  background-color: theme.get('backgroundColor');
  cursor: pointer;
  justify-content: center;
  border-radius: borders.radius('sm');
  height: 64px;
  width: 64px;

  &:hover {
    background-color: theme.get('secondaryBackgroundColor');
  }

  .withMobileClose & {
    display: flex;
  }

  @include breakpoints.up('small') {
    display: flex;
  }
}

.closeIcon {
  width: 16px;
  height: 16px;
  opacity: 0.8;
  stroke: theme.get('textColor');
}

.smallClose {
  height: 48px;
  width: 48px;
}

.content {
  display: flex;
  justify-content: center;
}

.contentSmall {
  padding: theme.spacing(0, 3, 4);
}

.contentBig {
  padding: theme.spacing(2.5);
}

.contentFullscreen {
  flex: 1;
  padding: theme.spacing(2.5, 2.5, 7);
}

.withoutPadding {
  padding: theme.spacing(0);
}
